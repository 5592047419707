import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginModel, ResetPasswordDto, User } from '../models/auth.models';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PagedResultSet } from '../models/core';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    apiUrl = environment.apiUrl;

    private currentUserSubject: BehaviorSubject<User | null>;
    public currentUser$: Observable<User | null>;

    constructor(private http: HttpClient, private router: Router) {
        //this.currentUserSubject = new BehaviorSubject<User | null>(this.getUserFromCookie());
        this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    async login(model: LoginModel) {
        //return await this.http.post<User>(this.apiUrl + `/account/login`, model, { withCredentials: true })
        return await this.http.post<User>(this.apiUrl + `/account/login`, model)
            .pipe(map((res: User) => {
                const user = res
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    sendEmail(email: any): Observable<PagedResultSet<string>> {
        return this.http.post<PagedResultSet<string>>(this.apiUrl + `/account/sendEmail`, email);
    }

    updatePassword(resetPasswordDto: ResetPasswordDto): Observable<PagedResultSet<ResetPasswordDto>> {
        return this.http.post<PagedResultSet<ResetPasswordDto>>(this.apiUrl + `/account/resetPassword`, resetPasswordDto);
    }

    // logout() {
    //     // Remove the token from the cookie by logging out on the backend
    //     // var res = this.http.post(this.apiUrl + '/account/logout', null, { withCredentials: true })
    //     var res = this.http.post(this.apiUrl + '/account/logout', null)
    //         .subscribe(() => {
    //             this.currentUserSubject.next(null);
    //             localStorage.removeItem('currentUser');
    //             this.router.navigate(['/account/login']);
    //         });
    //     return res;
    // }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/account/login']);
    }


    ////////////////
    checkSecretCode(sCode: any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          this.http.get<boolean>(this.apiUrl + `/account/secretCode?sCode=${encodeURIComponent(sCode)}`)
            .subscribe(response => {
              resolve(response);
            }, 
            
            error => {
              reject(error);
            });
        });
      }
      //////////////

    // Inside your AuthService class
    private getUserFromCookie(): User | null {
        const cookieValue = this.getCookie('myToken');
        if (cookieValue) {
            try {

                var decodedToken: any = jwtDecode(cookieValue);
                const user: User = {
                    username: decodedToken.nameid,
                    token: cookieValue
                };
                return user;

            } catch (error) {
                console.error('Error decoding JWT token:', error);
                return null;
            }
        } else {
            return null;
        }
    }

    private getCookie(name: string): string | null {
        debugger
        var n = name + '=';
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const trimmedCookie = cookie.trim();
            if (trimmedCookie.startsWith(n)) {
                const tokenValue = trimmedCookie.substring(n.length);
                return tokenValue;
            }
        }
        return null;
    }
}
