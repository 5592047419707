import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { PagedResultSet } from 'src/app/core/models/core';
import { UserDataDto } from 'src/app/core/models/user-profile';
import { ProfileComponent } from 'src/app/pages/contacts/profile/profile.component';
import { Subscription } from 'rxjs';
import { SendDataService } from 'src/app/core/services/send-data.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  username: string;
  data: UserDataDto[];
  imgData: string;
  receivedData: string;
  dataSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private dendDataService: SendDataService,
    private userProfileService: UserProfileService,

  ) {

    this.dataSubscription = this.dendDataService.getData().subscribe(value => {
      this.receivedData = value;
    });
  }

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    const currentUser = this.authFackservice.currentUserValue;
    
    if (currentUser) {
      this.username = currentUser.username;
      this.GetImageData(this.username);
    }
  }

  async GetImageData(userId: string) {
    await this.userProfileService.GetImageData(userId).subscribe(
      (result: PagedResultSet<UserDataDto>) => {
        this.data = result.results;
        this.data.forEach((userData) => {
          //console.log('userData:', userData);
          if (userData.image) {
            //console.log('userData.image:', userData.image);
            if (Array.isArray(userData.image)) {
              //console.log('userData.image is an array:', userData.image);
              userData.image.forEach((imageDetails) => {
                // Access properties of each imageDetails object
                //console.log(imageDetails.fileData);
                this.receivedData = imageDetails.fileData;
              });
            } else {
              //console.log('userData.image is not an array:', userData.image);
            }
          } else {
            console.log('userData.image is undefined or null');
          }
        });
      },
      (error) => {
        // Handle error
      }
    );
  }
  

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  getImageUrl(fileData: string | null): string {
    if (fileData) {
      // Assuming fileData is a base64 string
      return 'data:image/png;base64,' + fileData;
    } else {
      // Default image URL if no image data is available
      return 'assets/images/users/user-default.png';
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
