export class User {
    id?: number;
    username: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    email?: string;
}

export interface LoginModel {
    username: string;
    password: string;
  }

export interface ResetPasswordDto {
    email?: string;
    emailToken?: string;
    newPassword?: string;
    confirmPassword?: string;
  }

  // export class RegisterDto {
  //   userName: string;
  //   password: string;
  //   resetPasswordToken?: string;
  //   resetPasswordExpiry?: Date;
  // }
  