import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthfakeauthenticationService } from './core/services/authfake.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoginPage: boolean = false;
  constructor(private router: Router) {
  }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.isLoginPage = this.router.url === '/account/login';
    //   }
    // });
  }
}
