import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { User } from '../models/auth.models';
import { UserProfileService } from '../services/user.service';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    model: any = {};
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // array in local storage for registered users
        // tslint:disable-next-line: max-line-length
        //const users: any[] = JSON.parse(localStorage.getItem('users')) || [{ username: 'admin', password: '123456' }];

        // wrap in delayed observable to simulate server api call
        return of(User).pipe(mergeMap(() => {
            // authenticate
            if (request.url.endsWith('/account/login') && request.method === 'POST') {
                // send the login request to the server

                return next.handle(request).pipe(
                    catchError((error: HttpErrorResponse) => {

                        // Handle error for login endpoint
                        if (error.status === 401) {
                            // Unauthorized error (e.g., invalid credentials)
                            // You can handle this error by displaying a message to the user
                            // console.error('Unauthorized error:', error);
                            return throwError({ error: { message: 'Username or password is incorrect' } });
                        }

                        // Re-throw the error to propagate it to the caller
                        return throwError({ error: { message: 'Unknown Error!' } });
                    })
                );
            }

            // // get users
            // if (request.url.endsWith('/users') && request.method === 'GET') {
            //     // tslint:disable-next-line: max-line-length
            //     // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
            //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
            //         return of(new HttpResponse({ status: 200, body: users }));
            //     }
            //     else {
            //         // return 401 not authorised if token is null or invalid
            //         return throwError({ status: 401, error: { message: 'Unauthorised' } });
            //     }
            // }

            // get user by id
            // if (request.url.match(/\/users\/\d+$/) && request.method === 'GET') {
            //     // tslint:disable-next-line: max-line-length
            //     // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
            //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
            //         // find user by id in users array
            //         const urlParts = request.url.split('/');
            //         // tslint:disable-next-line: radix
            //         const id = parseInt(urlParts[urlParts.length - 1]);
            //         // tslint:disable-next-line: no-shadowed-variable
            //         const matchedUsers = users.filter(user => user.id === id);
            //         const user = matchedUsers.length ? matchedUsers[0] : null;

            //         return of(new HttpResponse({ status: 200, body: user }));
            //     } else {
            //         // return 401 not authorised if token is null or invalid
            //         return throwError({ status: 401, error: { message: 'Unauthorised' } });
            //     }
            // }

            // register user
            if (request.url.endsWith('/account/register') && request.method === 'POST') {
                return next.handle(request).pipe(
                    catchError((error: HttpErrorResponse) => {
                        // Handle error for login endpoint
                        if (error.status === 400) {
                            return throwError({ error: { message: 'User already exists!' } });
                        }
                        // if (error.status === 200) {
                        //     // Unauthorized error (e.g., invalid credentials)
                        //     // You can handle this error by displaying a message to the user
                        //     // console.error('Unauthorized error:', error);
                        //     return throwError({ error: { message: 'Success' } });
                        // }
                        // Re-throw the error to propagate it to the caller
                        return throwError({ error: { message: 'Unknown Error!' } });
                    })
                );
            }

            // delete user
            // if (request.url.match(/\/users\/\d+$/) && request.method === 'DELETE') {
            //     // tslint:disable-next-line: max-line-length
            //     // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
            //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
            //         // find user by id in users array
            //         const urlParts = request.url.split('/');
            //         // tslint:disable-next-line: radix
            //         const id = parseInt(urlParts[urlParts.length - 1]);
            //         for (let i = 0; i < users.length; i++) {
            //             const user = users[i];
            //             if (user.id === id) {
            //                 // delete user
            //                 users.splice(i, 1);
            //                 localStorage.setItem('users', JSON.stringify(users));
            //                 break;
            //             }
            //         }

            //         // respond 200 OK
            //         return of(new HttpResponse({ status: 200 }));
            //     } else {
            //         // return 401 not authorised if token is null or invalid
            //         return throwError({ status: 401, error: { message: 'Unauthorised' } });
            //     }
            // }

            //Add new product
            // if (request.url.endsWith('/Product/addProduct') && request.method === 'POST') {
            //     // send the login request to the server
            //     return next.handle(request).pipe(
            //         catchError((error: HttpErrorResponse) => {
            //             switch (error.status) {
            //                 case 500: return throwError({ error: { message: 'Internal Server Error!!!' } });
            //                 //case 200: return throwError({ error: { message: 'Success' } });
            //             }
            //             // Re-throw the error to propagate it to the caller
            //             return throwError({ error: { message: 'Unknown Error!' } });
            //         })
            //     );
            // }

            // pass through any requests not handled above
            return next.handle(request);

        }))

            // tslint:disable-next-line: max-line-length
            // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());
    }
}
