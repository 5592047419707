import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestUserData, UserDataDto } from '../models/user-profile';
import { ChangeResult, PagedResultSet } from '../models/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  async updateProfile(userData: RequestUserData) {
    debugger
    return await this.http.put<ChangeResult>(this.apiUrl + `/UserProfile/updateUser`, userData);
  }

  getProfileById(userId: string): Observable<PagedResultSet<UserDataDto>> {
    return this.http.get<PagedResultSet<UserDataDto>>(this.apiUrl + `/UserProfile/getUserData/${userId}`);
  }

  GetImageData(userId: string): Observable<PagedResultSet<UserDataDto>> {
    
    return this.http.get<PagedResultSet<UserDataDto>>(this.apiUrl + `/UserProfile/getImageData/${userId}`);
  }
}
